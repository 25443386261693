import request from '@/utils/request'


export function abnormal_query(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_managent/abnormal/query',
        method: 'post',
        data: data
    })
}

export function abnormal_create(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_managent/abnormal/create',
        method: 'post',
        data: data
    })
}

export function abnormal_del(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_managent/abnormal/del',
        method: 'post',
        data: data
    })
}

export function abnormal_update(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_managent/abnormal/update',
        method: 'post',
        data: data
    })
}
<template>
    <div>
        <!-- 添加异常组件弹窗 -->
        <el-dialog title="添加异常" :visible.sync="dialogFormVisible">
            <el-form :model="abnormalData">
                <el-form-item label="异常错误码" label-width="120px">
                    <el-input
                        v-model="abnormalData.error_code"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="异常信息" label-width="120px">
                    <el-input
                        v-model="abnormalData.error_info"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="是否生成人工工单" label-width="auto">
                    <el-select
                        v-model="abnormalData.manual"
                        placeholder="请选择"
                    >
                        <el-option label="生成" :value="true"></el-option>
                        <el-option label="不生成" :value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="异常严重等级" label-width="120px">
                    <el-select
                        v-model.number="abnormalData.error_level"
                        placeholder="请选择"
                    >
                        <el-option label="一级" :value="1"></el-option>
                        <el-option label="二级" :value="2"></el-option>
                        <el-option label="三级" :value="3"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="addAbnormal">确认</el-button>
            </div>
        </el-dialog>

        <!-- 编辑模块信息弹窗 -->
        <el-dialog
            title="编辑异常信息"
            :visible.sync="popov.dialogFormVisibleEdit"
            draggable
        >
            <el-form :model="editData">
                <el-form-item label="异常错误码" label-width="120px">
                    <el-input
                        v-model="editData.error_code"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="异常信息" label-width="120px">
                    <el-input
                        v-model="editData.error_info"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="是否生成人工工单" label-width="auto">
                    <el-select
                        v-model="editData.manual"
                        placeholder="请选择"
                    >
                        <el-option label="生成" :value="true"></el-option>
                        <el-option label="不生成" :value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="异常严重等级" label-width="120px">
                    <el-select
                        v-model.number="editData.error_level"
                        placeholder="请选择"
                    >
                        <el-option label="一级" :value="1"></el-option>
                        <el-option label="二级" :value="2"></el-option>
                        <el-option label="三级" :value="3"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="popov.dialogFormVisibleEdit = false">取消</el-button>
                <el-button type="primary" @click="editAbnormal">确认</el-button>
            </div>
        </el-dialog>

        <!-- 页头 -->
        <div class="commonInputTopBox">
            <infoHeader
                :content_name="
                    '模块异常配置 / ' +
                    infoData.product_name +
                    ' / ' +
                    infoData.module_name
                "
            />
            <div style="float: right; margin-top: -65px">
                <el-button type="primary" @click="dialogFormVisible = true"
                    >添加异常配置</el-button
                >
                <el-button type="danger" @click="deleteAbnormal"
                    >删除该模块</el-button
                >
            </div>
        </div>

        <!-- 内容 -->
        <el-row :gutter="20">
            <!-- 异常列表 -->
            <el-col :span="18">
                <el-descriptions title="异常列表"></el-descriptions>
                <el-table :data="abnormalList" style="width: 100%">
                    <el-table-column prop="id" label="系统ID"></el-table-column>
                    <el-table-column
                        prop="error_code"
                        label="错误码"
                    ></el-table-column>
                    <el-table-column
                        prop="error_code_snapshot"
                        label="错误码完整快照"
                    ></el-table-column>
                    <el-table-column
                        prop="error_info"
                        label="原因"
                    ></el-table-column>
                    <el-table-column
                        prop="error_level"
                        label="等级"
                    ></el-table-column>
                    <el-table-column
                        prop="manual"
                        label="是否生成人工工单"
                    ></el-table-column>
                    <el-table-column
                        label="操作"
                        fixed="right"
                        width="auto"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                @click="toResolvent(scope.row)"
                                >处理方法</el-button
                            >
                            <el-button
                                size="mini"
                                type="info"
                                @click="openEdit(scope.row)"
                                >编辑</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>

            <!-- 模块信息 -->
            <el-col :span="6">
                <el-descriptions
                    title="模块信息"
                    :column="2"
                    direction="vertical"
                    border
                >
                    <el-descriptions-item label="模块ID">{{
                        infoData.module_code
                    }}</el-descriptions-item>
                    <el-descriptions-item label="模块名称">{{
                        infoData.module_name
                    }}</el-descriptions-item>
                </el-descriptions>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {
    abnormal_query,
    abnormal_create,
    abnormal_update
} from '@/api/manage/operation/software_fault_managent/abnormal.js';
import { fault_module_delete } from '@/api/manage/operation/software_fault_managent/fault_module.js';

export default {
    name: 'start',
    data() {
        return {
            infoData: this.$route.query,
            editData: {},
            abnormalList: [],
            popov: {
                dialogFormVisibleEdit: false
            },
            formData: {
                edit: {}
            },
            abnormalData: {
                module_id: this.$route.query.id
            },
            dialogFormVisible: false
        };
    },
    created() {
        this.initOption();
    },
    methods: {
        openEdit(row) {
            this.editData = { ...row };
            this.popov.dialogFormVisibleEdit = true;
        },
        editAbnormal() {
            abnormal_update({ id: this.editData.id, ...this.editData }).then(
                res => {
                    if (res.code == 200) {
                        this.$message.success('编辑异常成功');
                        this.getAbnormal(); // 刷新列表数据
                        this.popov.dialogFormVisibleEdit = false;
                    } else {
                        this.$message.error(res.msg);
                    }
                }
            );
        },
        deleteAbnormal() {
            this.$confirm('确认删除该模块？')
                .then(() => {
                    fault_module_delete({ id: this.infoData.id }).then(() => {
                        this.$message.success('删除成功');
                        this.$router.go(-1);
                    });
                })
                .catch(() => {});
        },
        initOption() {
            this.getAbnormal();
        },
        submitEdit() {
            // 提交编辑后的数据
        },
        getAbnormal() {
            abnormal_query({ module_id: this.abnormalData.module_id }).then(
                res => {
                    this.abnormalList = res.data;
                }
            );
        },
        addAbnormal() {
            abnormal_create(this.abnormalData)
                .then(() => {
                    this.$message.success('添加异常成功');
                    this.getAbnormal(); // 刷新列表数据
                    this.dialogFormVisible = false;
                })
                .catch(error => {
                    this.$message.error(error);
                });
        },
        toResolvent(row) {
            row['product_name'] = this.infoData.product_name;
            row['module_name'] = this.infoData.module_name;
            this.$router.push({
                name: 'software_Fault_Info_Resolvent',
                query: row
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.commonControl-body {
    el-descriptions {
        margin-top: 20px;
    }
}
.box-card {
    width: 600px;
    margin: 10px 20px;
}
.manage-button-drawer {
    width: 400px;
    height: 50px;
    position: absolute;
    bottom: 5%;
    left: 17%;
}
.equipment-pitch-item {
    width: 98%;
    background-color: #efefef;
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
}
.device-list {
    height: 600px;
    overflow: auto;
}
.device-title {
    font-size: 16px;
    font-weight: 700;
    color: #303133;
    margin-top: 20px;
    margin-left: 22px;
}
</style>
